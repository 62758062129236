import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import PageTransition from "gatsby-plugin-page-transitions"

import SEO from "../components/seo"
import { linkResolver } from "../linkResolver"
import { UnstyledList } from "../components/commons"
import "../fonts/fonts.css"
import ArrowRight from "../images/arrow-right.png"

const Root = styled.div`
  width: 1280px;
  margin: 0 auto;
`

const Close = styled.div`
  padding-top: 7.35rem;
  padding-left: 5rem;

  a {
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-size: 0.9rem;
    letter-spacing: 3px;
    font-family: "visionlight";
  }
`

const Header = styled.header`
  display: flex;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
`

const Right = styled.div`
  margin-top: -1.25rem;
  padding-left: 5.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Subtitle = styled.span`
  font-family: "visionbold";
  text-transform: uppercase;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  line-height: 0.95;
  font-size: 22px;
  letter-spacing: 4px;
  text-align: right;
  display: inline-flex;
  padding-top: 2.4rem;
  padding-left: 13.5rem;
`

const Pagination = styled.div`
  text-transform: uppercase;
  color: #000;
  font-size: 1.12rem;
  letter-spacing: 4px;
  font-family: "visionlight";
  text-align: right;
  line-height: 1.1;
  align-self: center;
  margin-left: -2.7rem;

  div {
    letter-spacing: 6.5px;
  }
`

const H1 = styled.h1`
  font-weight: 900;
  font-size: 200px;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;
  color: #000;
  padding-left: 5rem;
  margin-top: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1.25rem;

  &:after {
    content: "";
    margin-top: 4.3rem;
    display: block;
    height: 2px;
    background-color: #000;
    width: 22rem;
  }
`

const Fields = styled.div`
  text-align: right;
  align-self: flex-end;
  padding-right: 9.1rem;
`

const H3 = styled.h3`
  font-weight: 700;
  font-size: 27px;
  letter-spacing: 0px;
  font-family: "visionbold";
  color: #000;
  text-transform: uppercase;
  letter-spacing: 5.25px;
  margin-bottom: 0.45rem;
`

const H4 = styled.h4`
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 3.5px;
  font-family: "visionbold";
  color: #000;
  text-transform: uppercase;
  margin-bottom: 0.05rem;
  margin-top: 1rem;
`

const Field = styled.li`
  text-transform: uppercase;
  font-family: "visionlight";
  font-size: 20px;
  letter-spacing: 4px;
  line-height: 1.2;
`

const Content = styled.div`
  display: flex;
  margin-top: 8.7rem;
  justify-content: flex-end;
  margin-right: 8rem;
`

const EmbedContainer = styled.div`
  --video--width: 1296;
  --video--height: 540;

  position: relative;
  padding-bottom: calc(
    var(--video--height) / var(--video--width) * 100%
  ); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  background: black;
  margin: 3rem;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
`

const Images = styled.div`
  margin: 3rem;
  figcaption {
    font-family: "visionlight";
    font-size: 12px;
    letter-spacing: 3.9px;
    font-style: italic;
  }
`

const Description = styled.div`
  width: 255px;

  &:before {
    content: "";
    height: 2px;
    width: 11rem;
    background: #000;
    display: block;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }
`

const P = styled.p`
  font-family: "visionlight";
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.5;
  width: 255px;
  margin: 0 auto;
`

const Figure = styled.figure`
  margin-bottom: 5rem;
  img {
    margin-bottom: 0;
  }

  figcaption {
    margin-top: 0;
  }
`

const Footer = styled.footer`
  margin-right: 8rem;
  margin-bottom: 135px;

  a {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    text-decoration: none;
  }

  img {
    margin-right: 1.5rem;
    margin-top: 0.2rem;
  }
`

const NextProjectNumber = styled.div`
  font-weight: 900;
  font-size: 448px;
  line-height: 448px;
  color: #000;
  font-family: "Playfair Display", serif;
`

const NextProject = styled.div`
  font-family: "visionbold";
  color: #000;
  text-transform: uppercase;
  font-size: 27px;
  margin-top: 2rem;
  margin-right: 1.6rem;
  letter-spacing: 5px;
`

export const query = graphql`
  query ProjectQuery($uid: String!) {
    prismic {
      project(lang: "fr-fr", uid: $uid) {
        _meta {
          uid
        }
        approach
        category
        description
        fields {
          field {
            ... on PRISMIC_Field {
              name
              _linkType
            }
          }
        }
        images {
          image1
        }
        title
        year
        image
        video
      }
      allProjects(sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const Project = ({ data }) => {
  const doc = data.prismic.project

  if (!doc) return null

  const projects = data.prismic.allProjects.edges
  const title = doc.title[0].text
  const category = doc.category[0].text
  const year = doc.year[0].text
  const fields = doc.fields
  const approach = doc.approach
  const description = doc.description
  const images = doc.images[0].image1 && doc.images
  const video = doc.video && doc.video.html
  const numberOfProjects = projects.length
  const index = projects.findIndex(it => it.node._meta.uid === doc._meta.uid)
  const next = projects[index + 1]
  const nextUid = next ? next.node._meta.uid : projects[0].node._meta.uid
  const nextNumber = next ? index + 2 : 1

  return (
    <Root>
      <SEO title={title} />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <PageTransition>
        <Close>
          <Link to="/">Fermer</Link>
        </Close>
        <Header>
          <Subtitle>
            {category} <br />
            {year}
          </Subtitle>
          <Right>
            <Pagination>
              Projet
              <div>
                {index + 1}—{numberOfProjects}
              </div>
            </Pagination>
            <H1>{title}</H1>
            <Fields>
              <H3>Domaine d'intervention</H3>
              <UnstyledList>
                {fields.map((it, index) => {
                  return <Field key={index}>{it.field.name}</Field>
                })}
              </UnstyledList>
            </Fields>
          </Right>
        </Header>
        <Main>
          <Content>
            <LeftContent>
              <Images>
                {images &&
                  images.map((it, index) => {
                    const { url, alt } = it.image1

                    return (
                      <Figure key={index}>
                        <img src={url} alt={alt} />
                        <figcaption>{alt}</figcaption>
                      </Figure>
                    )
                  })}
              </Images>
              {video && (
                <EmbedContainer>
                  <div dangerouslySetInnerHTML={{ __html: video }} />{" "}
                </EmbedContainer>
              )}
            </LeftContent>
            <Description>
              <H4>Contexte</H4>
              <P>
                {description &&
                  description.map((it, index) => (
                    <span key={index}>
                      {it.text}
                      <br />
                    </span>
                  ))}
              </P>
              <H4>Démarche créative</H4>
              <P>
                {approach &&
                  approach.map((it, index) => (
                    <span key={index}>
                      {it.text}
                      <br />
                    </span>
                  ))}
              </P>
            </Description>
          </Content>
        </Main>
        <Footer>
          <Link to={linkResolver({ type: "project", uid: nextUid })}>
            <NextProjectNumber>{`0${nextNumber}`.slice(-2)}</NextProjectNumber>
            <NextProject>Projet suivant</NextProject>
            <img src={ArrowRight} />
          </Link>
        </Footer>
      </PageTransition>
    </Root>
  )
}

export default Project
